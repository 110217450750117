<template>
  <b-card-code>
    <b-button
      v-if="this.$store.state.app.user.is_staff"
      type="button"
      variant="success"
      class="btn ml-auto m-1 text-white"
      size="sm"
    >
      <router-link style="color: #ffff !important" :to="'/report/template/add'"
        >+ Add New</router-link
      >
    </b-button>
    <b-button
      v-if="this.$store.state.app.user.is_staff"
      type="button"
      variant="primary"
      class="btn ml-auto m-1"
      size="sm"
      @click="showSidebar()"
    >
      Reference Variables
      <!-- <router-link class="text-white" :to="'/report/template/add'"
        >Add New</router-link
      > -->
    </b-button>
    <b-table
      responsive
      id="dataTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      hover
      style="min-height: 200px"
    >
      <template #cell(index)="data">
        {{ data.index + 1 + perPage * (currentPage - 1) }}
      </template>
      <template #cell(preview)="data">
        <a
          class="text-primary"
          @click="
            downloadTemplate(data.item.template_id, data.item.template_doc_path)
          "
          >Download</a
        >
      </template>
      <template #cell(template_meta_fields)="data">
        <b-badge
          variant="primary"
          class="mr-1"
          v-for="(variable, key) in JSON.parse(data.item.template_meta_fields)"
          :key="key"
          >{{ variable.variable_label }}</b-badge
        >
        <!-- <b-badge variant="success" v-else>Yes</b-badge> -->
      </template>
      <template #cell(actions)="data">
        <!-- <button @click="editOrg(data.item.org_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteOrg(data.item.org_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button> -->
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="edit(data.item.template_id)"
            >Edit</b-dropdown-item
          >

          <!-- <b-dropdown-item @click="allocateScanners(data.item.org_id)">Default Scanners</b-dropdown-item> -->
          <b-dropdown-divider />
          <b-dropdown-item
            @click="gotoDelete(data.item.template_id, data.item.template_name)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>

    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="dataTable"
      use-router
    />

    <b-sidebar
      id="sidebar-variable-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="600px"
    >
      <!-- Header -->
      <b-card>
        <div class="row">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h4 class="">Reference Variables</h4>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="closeSidebar()"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 my-1"
            v-for="(value, key) in referanceVariables"
            :key="key"
          >
            <h5 class="text-primary">{{ key }}</h5>
            <div
              class="border-primary rounded p-1"
              style="height: 150px; overflow-y: auto"
            >
              {{ value }}
            </div>
          </div>
        </div>
        <!-- end -->
      </b-card>
    </b-sidebar>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ templateName }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteItem()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BSidebar,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BSidebar,
    BFormTextarea,
    BCardText,
  },
  data() {
    return {
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "template_name", label: "Name" },
        { key: "template_meta_fields", label: "Variables" },
        { key: "preview", label: "Template" },
        { key: "actions", label: "Actions" },
      ],
      name_filter: "",
      referanceVariables: [],
      directVariables: [],
      assetVariables: [],
      vulnVariables: [],
      templateName: "",
      template_id: "",
      openDeleteModal: false,
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.load();
    },
  },
  created: function () {
    this.load();
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function (search = false) {
      let url =
        process.env.VUE_APP_BASEURL +
        "report/report-template/?limit=0&offset=0";
      if (this.$route.query.page && !search) {
        this.currentPage = this.$route.query.page;
        url = url + "&page=" + this.currentPage;
      }
      if (search) url = url + "&page=1";

      if (this.name_filter) {
        if (search) url = url + "&title=" + this.name_filter;
        // this.currentPage = 1
      }

      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        //   this.currentPage = 1
        //   this.perPage = 10
        //   this.rows = res.data.count
      });
    },
    downloadTemplate(id, path) {
      // Retrieve doc name from path (old code)
      // let path_as_json = JSON.parse(path);
      // let key = path_as_json.key;
      // let name = key.substring(key.lastIndexOf("/") + 1, key.length);

      // new code
      let name;
      let path_as_json = null;

      // Check if path is a JSON string
      try {
        path_as_json = JSON.parse(path);
      } catch (error) {
        // Not a valid JSON string, assume it's a direct file path
        name = path.substring(path.lastIndexOf("/") + 1);
      }

      if (path_as_json) {
        // JSON string case
        name = path_as_json.key.substring(
          path_as_json.key.lastIndexOf("/") + 1
        );
      }

      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report-template/" +
          id +
          "/download",
        responseType: "blob",
      };
      this.$http(options).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); //or any other extension
        document.body.appendChild(link);
        link.click();
        // this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
    },
    edit(id) {
      this.$router.push({ name: "Edit Template", params: { id: id } });
    },
    gotoDelete(template_id, template_name) {
      this.openDeleteModal = true;
      this.template_id = template_id;
      this.templateName = template_name;
    },
    deleteItem() {
      // this.templateName = template_name;
      // if (
      //   confirm("Do you really want to delete this " + this.templateName + "?")
      // ) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report-template/" +
          this.template_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
    getReferanceVariables: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "report/list-template-variables",
      };
      this.$http(options).then((res) => {
        this.referanceVariables = res.data;
        // this.directVariables = res.data.direct_variables;
        // this.assetVariables = res.data.asset_variables;
        // this.vulnVariables = res.data.vuln_variables;
      });
    },
    showSidebar: function () {
      this.getReferanceVariables();
      this.$root.$emit("bv::toggle::collapse", "sidebar-variable-handler");
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variable-handler");
    },
  },
};
</script>
<style scoped>
.b-sidebar.sidebar-lg {
  width: 50rem !important;
}

.w-50rem {
  width: 50rem !important;
}

::v-deep .dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}
</style>
